import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/DashboardLayout/DashboardLayout';
import axios from 'axios';
import Pagination from '../../../component/Pagination/Pagination';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {apiUrl} from '../../../utils';

import './Fund.css';

function Account() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const initialState = {
        accounts: [],
        account: null,
        sender: null,
        amount: '',
        isSubmitting: false,
        errorMessage: null,
        successMessage: null,
        refresh: false,
        searchItems: null
    };

    const [data, setData] = useState(initialState);

    const [ showModal, setShowModal ] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);

    //  Get Current Items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = data.accounts.slice(indexOfFirstItem, indexOfLastItem);

    const [currentItems, setCurrentItems] = useState();

    //  Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        if (data.searchItems) {
            const currentItems = data.searchItems.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        } else {
            const currentItems = data.accounts.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        }
    }, [data.accounts, currentPage, data.searchItems])

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;
        //  accounts
        axios.get(`${apiBaseUrl}/account`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(res => {
            setData({
                ...data,
                accounts: res.data.accounts,
                successMessage: null,
                errorMessage: null,
            });
        })
        .catch(err => console.log(err))

    }, [apiBaseUrl, data.refresh]);

    useEffect(() => {
        const user = cookie.session.user;
        const sender = data.accounts.filter(account => account.userId === user._id);
        setData({
            ...data,
            sender: sender[0]
        })
    }, [data.accounts])

    const handleChange = (e) => {
        e.preventDefault();

        setData({
            ...data,
            [e.target.name]: e.target.value
        });

    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (e.target.value !== '') {
            const searchString = e.target.value.toLowerCase();
            const filteredItems = data.accounts.filter(item => item.accountName.toLowerCase().includes(searchString) || item.accountNumber.includes(searchString));

            setCurrentPage(1);

            setData({
                ...data,
                searchItems: filteredItems
            })
        } else {
            setData({
                ...data,
                searchItems: null
            })
        }
    }

    const handleView = (id) => {

        const account = data.accounts.filter(account => account._id == id);
        openModal();
        // console.log(account)
        setData({
            ...data,
            account: account
        });
        // console.log(account)

    }

    const fundAcct = async (e) => {
        e.preventDefault();
        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null,
            successMessage: null
        });

        if (data.amount > 0) {

            try {
                const res = await axios.patch(`${apiBaseUrl}/account/${data.account[0]._id}/fund`, {
                    senderId: data.sender._id,
                    amount: data.amount
                }, 
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
        
                if (res) {
                    closeModal();
                    setData({
                        ...data,
                        amount: '',
                        refresh: !data.refresh,
                        isSubmitting: false,
                        errorMessage: null,
                        successMessage: 'Fund Transfered Successfully'
                    });
    
                    return;
                }
    
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: 'Oops! Something went wrong. Try again later.',
                    successMessage: null
                });
            } catch (error) {
                setData({
                    ...data,
                    isSubmitting: false,
                    successMessage: null,
                    errorMessage: error.statusText || "Couldn't make the transfer. Try again later!"
                });
            }

        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty field and/or invalid input',
                successMessage: null
            });
        }

    }

    const openModal = () => {

        setShowModal(true);

    }

    const closeModal = () => {

        setShowModal(false);
        
    }

    return (
        <DashboardLayout activePage={2}>
            <main>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">Accounts</div>
                    </div>
                </section>

                {/* Account */}
                <section className="account-section">
                    <div className="container">
                        {
                            data.successMessage && (
                                <div className="alert alert-success">{data.successMessage}</div>
                            )
                        }
                        <div className="form-group mb-3">
                            <input type="text" name="search" className="form-control" placeholder="Search" onChange={handleSearch} />
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Account Number</th>
                                        <th scope="col">Account Name</th>
                                        <th scope="col">Balance($)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems ? 
                                            currentItems.map((account, i) => {
                                                return (
                                                    <tr key={i + 1}>
                                                        <td className="">
                                                            <button type="button" className="btn btn-primary" onClick={() => handleView(account._id)}>Fund</button>
                                                        </td>
                                                        <td className="">{account.accountNumber}</td>
                                                        <td className="">{account.accountName}</td>
                                                        <td className="text-right">{account.balance.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                    </tr>
                                                )
                                            })
                                            : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                            <Pagination 
                                numberPerPage={itemsPerPage} 
                                totalNumber={ data.searchItems ? data.searchItems.length : data.accounts.length } 
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </section>

                {/* ACCOUNT Modal */}
                <div className={`modal-overlay ${showModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showModal ? 'show' : ''}`} id="loanModal" tabIndex="-1" aria-labelledby="loanModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">ACCOUNT</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    <div className="modal-footer">
                                        {
                                            data.errorMessage && (
                                                <div className="alert alert-danger">{data.errorMessage}</div>
                                            )
                                        }
                                        <form onSubmit={fundAcct} noValidate>
                                            <div className="form-group">
                                                <label className="">Amount($)</label>
                                                <input type="number" name="amount" className="form-control" onChange={handleChange} value={data.amount} />
                                            </div>
                                            <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "FUND ACCOUNT"}</button>
                                        </form>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr className="">
                                                    <th className="">ACCOUNT NUMBER</th>
                                                    <td className="">{data.account ? data.account[0].accountNumber : ''}</td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">ACCOUNT NAME</th>
                                                    <td className="">{data.account ? data.account[0].accountName : ''}</td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">BALANCE</th>
                                                    <td className="">$
                                                        {
                                                            data.account ? data.account[0].balance.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">STATUS</th>
                                                    <td className="">
                                                        {
                                                            data.account ? 
                                                                data.account[0].user ? 
                                                                    data.account[0].user.verified ? 
                                                                        (
                                                                            <span className="text-success">Verified</span>
                                                                        )
                                                                        : (
                                                                            <span className="text-danger">Not verified</span>
                                                                        )
                                                                    : null
                                                                : null
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </DashboardLayout>
    )
}

export default Account;
