import React, { useState, useEffect, useContext } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import ErrorBoundary from '../../component/ErrorBoundary/ErrorBoundary';
import PageLoader from '../../component/PageLoader/PageLoader';
import {apiUrl} from '../../utils';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Reset.css';

import logo from '../../utils/assets/logo.png';

function ResetPassword({ location, match }) {

    const [cookie, setCookie] = useCookies(["session"]);

    const apiBaseUrl = apiUrl;

    const initialState = {
        password: "",
        confirmPassword: "",
        token: null,
        displayPassword: false,
        isSubmitting: false,
        errorMessage: null,
        successMessage: null,
        isLoading: true
    };

    const [data, setData] = useState(initialState);

    const history = useHistory();

    const parsed = queryString.parse(location.search);

    useEffect(() => {
        if (parsed.user === undefined || parsed.user === '' || parsed.user === null) {
            history.push('/');
        } else {
            setData({
                ...data,
                token: parsed.user,
                isLoading: false
            })
        }
    }, [parsed.user]);

    const handleChange = (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorMessage: null,
            successMessage: null,
            [e.target.name]: e.target.value
        });

    };

    const toggleDisplayPassword = (e) => {
        e.preventDefault();

        setData({
            ...data,
            displayPassword: !data.displayPassword
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data.password !== '' && data.confirmPassword !== '' && data.token) {
            
            if (data.password === data.confirmPassword) {
                setData({
                    ...data,
                    isSubmitting: true,
                    errorMessage: null
                });
        
                axios.patch(`${apiBaseUrl}/auth/reset-password`, {
                    resetLink: data.token,
                    newPassword: data.password
                })
                .then(res => {
    
                    setData({
                        ...data,
                        isSubmitting: false,
                        successMessage: 'Password Reset successful.',
                        errorMessage: null,
                        password: "",
                        confirmPassword: "",
                    });
                })
                .catch(error => {
                    setData({
                        ...data,
                        isSubmitting: false,
                        successMessage: null,
                        errorMessage: error.statusText || 'Password Reset Failed. Try again!'
                    });
                    
                });
            } else {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "Passwords don't match"
                });
            }

        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty fields'
            });
        }

    };

    return (
        <ErrorBoundary>
            {
                data.isLoading ? 
                    (
                        <PageLoader />
                    )
                    : (
            <main>
                {console.log(data)}
                <section className="reset-section">
                    <div className="container">
                        <div className="reset">
                            <div className="d-flex justify-content-center align-items-center mb-3">
                                <a className="navbar-brand" href="/">
                                    <img className="logo" src={logo} alt="logo" />
                                </a>
                            </div>
                            <h4 className="text-center mb-4">Reset Password</h4>
                            {
                                data.successMessage && (
                                    <div className="alert alert-success">{data.successMessage}&nbsp;&nbsp;<a className="text-primary" href="/login">LOGIN</a></div>
                                )
                            }
                            {
                                data.errorMessage && (
                                    <div className="alert alert-danger">{data.errorMessage}</div>
                                )
                            }
                            <form className="w-100" onSubmit={handleSubmit} >
                                <div className="input-group mb-3">
                                    <input type={ data.displayPassword ? 'text' : 'password' } name="password" className="form-control" placeholder="New Password" onChange={handleChange} value={data.password} noValidate />
                                    <div className="input-group-append">
                                        <span className="input-group-text" onClick={toggleDisplayPassword}>
                                            { data.displayPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <input type="password" name="confirmPassword" className="form-control" placeholder="Confirm password" onChange={handleChange} value={data.confirmPassword} noValidate />
                                </div>
                                <p className="text-center">
                                    <button type="submit" className="btn btn-primary btn-block" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "SUBMIT"}</button>
                                </p>
                            </form>
                        </div>
                    </div>
                </section>
                
            </main>
            )
            }
        </ErrorBoundary>
    )
}

export default ResetPassword;
