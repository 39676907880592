import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/DashboardLayout/DashboardLayout';
import axios from 'axios';
import Pagination from '../../../component/Pagination/Pagination';

import { faTimes, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {apiUrl} from '../../../utils';

import './Accounts.css';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

function Account() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const initialState = {
        accounts: [],
        account: null,
        isSubmitting: false,
        errorMessage: null,
        successMessage: null,
        refresh: false,
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        accountType: null,
        displayPassword: false,
        searchItems: null,
        showEditAccountType: false,
        showEditAccountName: false
    };

    const [data, setData] = useState(initialState);

    const [ showAccountModal, setShowAccountModal ] = useState(false);
    const [ showRegisterModal, setShowRegisterModal ] = useState(false);
    const [ showImageModal, setShowImageModal] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);

    //  Get Current Items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = data.accounts.slice(indexOfFirstItem, indexOfLastItem);

    const [currentItems, setCurrentItems] = useState();

    //  Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        if (data.searchItems) {
            const currentItems = data.searchItems.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        } else {
            const currentItems = data.accounts.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        }
    }, [data.accounts, currentPage, data.searchItems])

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;
        //  account
        axios.get(`${apiBaseUrl}/account`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(res => {
            setData({
                ...data,
                accounts: res.data.accounts,
                successMessage: null,
                errorMessage: null,
            });
        })
        .catch(err => console.log(err))
    }, [apiBaseUrl, data.refresh]);

    const handleChange = (e) => {
        e.preventDefault();

        setData({
            ...data,
            [e.target.name]: e.target.value
        });

    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (e.target.value !== '') {
            const searchString = e.target.value.toLowerCase();
            const filteredItems = data.accounts.filter(item => item.accountName.toLowerCase().includes(searchString) || item.accountNumber.includes(searchString));

            setCurrentPage(1);

            setData({
                ...data,
                searchItems: filteredItems
            })
        } else {
            setData({
                ...data,
                searchItems: null
            })
        }
    }

    const toggleDisplayPassword = (e) => {
        e.preventDefault();

        setData({
            ...data,
            displayPassword: !data.displayPassword
        });
    }

    const handleView = (id) => {

        const account = data.accounts.filter(account => account._id == id);
        openModal();
        // console.log(account)
        setData({
            ...data,
            account: account,
            // accountType: account[0].accountType,
            firstname: account[0].user ? account[0].user.firstName : '',
            lastname: account[0].user ? account[0].user.lastName : ''
        });
        // console.log(account)

    }

    const handleImageView = (id) => {

        const account = data.accounts.filter(account => account._id == id);
        openImageModal();
        setData({
            ...data,
            account: account
        });

    }

    const verifyUser = async (id) => {
        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true
        });

        try {
            const res = await axios.patch(`${apiBaseUrl}/user/${id}/verify`,{}, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
    
            // console.log('After waiting')
    
            if (res) {
                closeModal();
                setData({
                    ...data,
                    refresh: !data.refresh,
                    isSubmitting: false
                });
            }
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || 'Oops. Something went wrong. Try again!'
            });
        }

    }

    const blockUser = async (id) => {

        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true
        });

        try {
            const res = await axios.patch(`${apiBaseUrl}/user/${id}/block`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
    
            if (res) {
                closeModal();
                setData({
                    ...data,
                    refresh: !data.refresh,
                    isSubmitting: false
                });
            }
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || 'Oops. Something went wrong. Try again!'
            });
        }
        
    }

    const freezeUser = async (id) => {

        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true
        });

        try {
            const res = await axios.patch(`${apiBaseUrl}/user/${id}/freeze`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
    
            if (res) {
                closeModal();
                setData({
                    ...data,
                    refresh: !data.refresh,
                    isSubmitting: false
                });
            }
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || 'Oops. Something went wrong. Try again!'
            });
        }
        
    }

    const unFreezeUser = async (id) => {

        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true
        });

        try {
            const res = await axios.patch(`${apiBaseUrl}/user/${id}/unfreeze`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
    
            if (res) {
                closeModal();
                setData({
                    ...data,
                    refresh: !data.refresh,
                    isSubmitting: false
                });
            }
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || 'Oops. Something went wrong. Try again!'
            });
        }
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (data.email !== '' && data.firstname !== '' && data.lastname !== '' && data.password !== '' && data.accountType !== '') {
            
                setData({
                    ...data,
                    isSubmitting: true,
                    errorMessage: null
                });
        
                const res = await axios.post(`${apiBaseUrl}/auth/register`, {
                    firstName: data.firstname,
                    lastName: data.lastname,
                    email: data.email,
                    password: data.password,
                    accountType: data.accountType
                })

                if (res) {
                    closeModal();
                    setData({
                        ...data,
                        isSubmitting: false,
                        successMessage: 'Registration successful. Check your email to activate account.',
                        errorMessage: null,
                        firstname: "",
                        lastname: "",
                        email: "",
                        password: "",
                        refresh: !data.refresh
                    });
                    return;
                }
                
                setData({
                    ...data,
                    isSubmitting: false,
                    successMessage: null,
                    errorMessage: 'Email already exists. Try again!'
                });
    
            } else {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: 'Empty fields'
                });
            }
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || 'Email already exists. Try again!'
            });
        }

    };

    const updateAcctType = async (e) => {
        e.preventDefault();
        const user = cookie.session.user;
        const token = cookie.session.token;

        try {
            if (data.accountType) {
                setData({
                    ...data,
                    isSubmitting: true
                });

                const res = await axios.patch(`${apiBaseUrl}/account/${data.account[0]._id}`,{
                    accountType: data.accountType
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
        
                if (res) {
                    closeModal();
                    setData({
                        ...data,
                        refresh: !data.refresh,
                        isSubmitting: false,
                        showEditAccountType: false
                    });
                }
            } else {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: 'Select an Account Type'
                });
            }
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || 'Oops. Something went wrong. Try again!'
            });
        }

    }

    const updateAcctName = async () => {
        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true
        });

        try {
            const res = await axios.patch(`${apiBaseUrl}/user/${data.account[0].user ? data.account[0].user._id : null}/update-name`,{
                firstName: data.firstname,
                lastName: data.lastname,
                accountId: data.account[0]._id
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
    
            if (res) {
                closeModal();
                setData({
                    ...data,
                    refresh: !data.refresh,
                    isSubmitting: false,
                    showEditAccountName: false
                });
            }
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || 'Oops. Something went wrong. Try again!'
            });
        }

    }

    const openRegisterModal = () => {
        setData({
            ...data,
            accountType: 'Savings'
        });
        setShowRegisterModal(true);
    }

    const openImageModal = () => {

        setShowImageModal(true);

    }

    const openModal = () => {

        setShowAccountModal(true);

    }

    const closeModal = () => {

        setShowRegisterModal(false);
        setShowAccountModal(false);
        setShowImageModal(false);
        
    }

    return (
        <DashboardLayout activePage={1}>
            <main>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">Accounts</div>
                    </div>
                </section>

                {/* Account */}
                <section className="account-section">
                    <div className="container">
                        <p className="text-right">
                            <button type="button" className="btn btn-primary" onClick={() => openRegisterModal()}>REGISTER ACCOUNT</button>
                        </p>
                        <div className="form-group mb-3">
                            <input type="text" name="search" className="form-control" placeholder="Search" onChange={handleSearch} />
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Account Number</th>
                                        <th scope="col">Account Name</th>
                                        <th scope="col">Balance($)</th>
                                        <th scope="col">E-mail</th>
                                        <th scope="col">Status</th>
                                        {/* <th scope="col">PHOTO</th> */}
                                        <th scope="col">CREATED</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems ? 
                                            currentItems.map((account, i) => {
                                                return (
                                                    <tr key={i + 1}>
                                                        <td className="">
                                                            <button type="button" className="btn btn-primary" onClick={() => handleView(account._id)}>View</button>
                                                        </td>
                                                        <td className="">{account.accountNumber}</td>
                                                        <td className="">{account.accountName}</td>
                                                        <td className="text-right">{account.balance.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                        <td className="">{account.user ? account.user.email : ''}</td>
                                                        <td className="">
                                                            {
                                                                account.user ? 
                                                                    account.user.verified ? 
                                                                        (
                                                                            <span className="text-success">Verified</span>
                                                                        )
                                                                        : (
                                                                            <span className="text-danger">Not verified</span>
                                                                        )
                                                                    : null
                                                            }
                                                            {
                                                                account.user ? 
                                                                    account.user.freeze ? 
                                                                        (
                                                                            <span className="text-danger">&nbsp; | freezed</span>
                                                                        )
                                                                        : null
                                                                    : null
                                                            }
                                                        </td>
                                                        {/* <td className="">
                                                            {
                                                                account.user ? 
                                                                    account.user.image ? 
                                                                        (<img className="" src={`${apiBaseUrl}/${account.user.image}`} alt="avatar" style={{width: '50px', height: '50px', borderRadius: '100%', cursor: 'pointer'}} onClick={() => handleImageView(account._id)} />)
                                                                        : null
                                                                    : null
                                                            }
                                                        </td> */}
                                                        <td className="">{(new Date(account.createdAt)).toDateString()}</td>
                                                    </tr>
                                                )
                                            })
                                            : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                            <Pagination 
                                numberPerPage={itemsPerPage} 
                                totalNumber={ data.searchItems ? data.searchItems.length : data.accounts.length } 
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </section>

                {/* ACCOUNT Modal */}
                <div className={`modal-overlay ${showAccountModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showAccountModal ? 'show' : ''}`} id="accountModal" tabIndex="-1" aria-labelledby="loanModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">ACCOUNT</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    <div className="modal-footer">
                                        {
                                            data.account ? 
                                                data.account[0].user ? 
                                                    data.account[0].user.freeze ? 
                                                        (
                                                            <button type="button" className="btn btn-sm btn-secondary" onClick={() => unFreezeUser(data.account[0].user._id)} disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "UNFREEZE"}</button>
                                                        )
                                                    : (
                                                        <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting} onClick={() => freezeUser(data.account[0].user._id)}>{data.isSubmitting ? "Loading..." : "FREEZE"}</button>
                                                    )
                                                : null
                                            : null
                                        }
                                        {
                                            data.account ? 
                                                data.account[0].user ? 
                                                    data.account[0].user.verified ? 
                                                        (
                                                            <button type="button" className="btn btn-sm btn-secondary" onClick={() => blockUser(data.account[0].user._id)} disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "BLOCK"}</button>
                                                        )
                                                    : (
                                                        <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting} onClick={() => verifyUser(data.account[0].user._id)}>{data.isSubmitting ? "Loading..." : "VERIFY"}</button>
                                                    )
                                                : null
                                            : null
                                        }
                                    </div>
                                    {
                                        data.errorMessage && (
                                            <div className="alert alert-danger">{data.errorMessage}</div>
                                        )
                                    }
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr className="">
                                                    <th className="">ACCOUNT NUMBER</th>
                                                    <td className="">{data.account ? data.account[0].accountNumber : ''}</td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">ACCOUNT NAME</th>
                                                    <td className="">{data.account ? data.account[0].accountName : ''} &nbsp; <FontAwesomeIcon icon={faEdit} style={{cursor: 'pointer'}} onClick={() => setData({...data, showEditAccountName: !data.showEditAccountName})} /></td>
                                                </tr>
                                                <tr className={`form-type ${data.showEditAccountName ? 'show' : ''}`}>
                                                    <td colSpan="2">
                                                        <form onSubmit={updateAcctName}>
                                                            <div className="form-group mb-3">
                                                                <input type="text" name="firstname" className="form-control" placeholder="First Name" onChange={handleChange} value={data.firstname} noValidate />
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <input type="text" name="lastname" className="form-control" placeholder="Last Name" onChange={handleChange} value={data.lastname} noValidate />
                                                            </div>
                                                            <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "Update"}</button>
                                                        </form>
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">BALANCE</th>
                                                    <td className="">$
                                                        {
                                                            data.account ? data.account[0].balance.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">ACCOUNT TYPE</th>
                                                    <td className="">{data.account ? data.account[0].accountType : ''} &nbsp; <FontAwesomeIcon icon={faEdit} style={{cursor: 'pointer'}} onClick={() => setData({...data, showEditAccountType: !data.showEditAccountType})} /></td>
                                                </tr>
                                                <tr className={`select-type ${data.showEditAccountType ? 'show' : ''}`}>
                                                    <td colSpan="2">
                                                        <form onSubmit={updateAcctType}>
                                                            <div className="form-group mb-3">
                                                                <select name="accountType" onChange={handleChange} className="form-select w-100" aria-label="account type">
                                                                    <option>Select-Account-Type</option>
                                                                    <option value="Savings">Savings</option>
                                                                    <option value="Current">Current</option>
                                                                    <option value="Overdraft">Overdraft</option>
                                                                    <option value="Teen Account">Teen Account (for children)</option>
                                                                    <option value="FX Account">FX Account</option>
                                                                </select>
                                                            </div>
                                                            <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "Update"}</button>
                                                        </form>
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">Email</th>
                                                    <td className="">{data.account ? data.account[0].user ? data.account[0].user.email : '' : ''}</td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">STATUS</th>
                                                    <td className="">
                                                        {
                                                            data.account ? 
                                                                data.account[0].user ? 
                                                                    data.account[0].user.verified ? 
                                                                        (
                                                                            <span className="text-success">Verified</span>
                                                                        )
                                                                        : (
                                                                            <span className="text-danger">Not verified</span>
                                                                        )
                                                                    : null
                                                                : null
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Register Modal */}
                <div className={`modal-overlay ${showRegisterModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showRegisterModal ? 'show' : ''}`} id="registerModal" tabIndex="-1" aria-labelledby="loanModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">ADD ACCOUNT</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    <div className="register">
                                        {
                                            data.successMessage && (
                                                <div className="alert alert-success">{data.successMessage}</div>
                                            )
                                        }
                                        {
                                            data.errorMessage && (
                                                <div className="alert alert-danger">{data.errorMessage}</div>
                                            )
                                        }
                                        <form className="w-100" onSubmit={handleSubmit} >
                                            <div className="form-group mb-3">
                                                <input type="text" name="firstname" className="form-control" placeholder="First Name" onChange={handleChange} value={data.firstname} noValidate />
                                            </div>
                                            <div className="form-group mb-3">
                                                <input type="text" name="lastname" className="form-control" placeholder="Last Name" onChange={handleChange} value={data.lastname} noValidate />
                                            </div>
                                            <div className="form-group mb-3">
                                                <input type="email" name="email" className="form-control" placeholder="Email" onChange={handleChange} value={data.email} noValidate />
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type={ data.displayPassword ? 'text' : 'password' } name="password" className="form-control" placeholder="Password" value={data.password} onChange={handleChange} noValidate />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={toggleDisplayPassword}>
                                                        { data.displayPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="accountType" className="form-label">Account Type</label>
                                                <select name="accountType" onChange={handleChange} className="form-select w-100" aria-label="account type">
                                                    <option value="Savings">Savings</option>
                                                    <option value="Current">Current</option>
                                                    <option value="Overdraft">Overdraft</option>
                                                    <option value="Teen Account">Teen Account (for children)</option>
                                                    <option value="FX Account">FX Account</option>
                                                </select>
                                            </div>
                                            <p className="text-center">
                                                <button type="submit" className="btn btn-primary btn-block" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "REGISTER"}</button>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Image Modal */}
                <div className={`modal-overlay ${showImageModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showImageModal ? 'show' : ''}`} id="imageModal" tabIndex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">{data.account ? data.account[0].accountName : null}</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex justify-content-center align-items-center w-100 p-2">
                                    {
                                        data.account ? 
                                            data.account[0].user ? 
                                                (
                                                    <img 
                                                        className=""
                                                        src={`${apiBaseUrl}/${data.account[0].user.image}`}
                                                        alt="avatar" 
                                                        style={{
                                                            maxWidth: '100%',
                                                        }}
                                                    />
                                                )
                                                : null
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </DashboardLayout>
    )
}

export default Account;
