import React, { useState } from 'react';
import DashHeader from '../../component/DashHeader/DashHeader';
import DashSidebar from '../../component/DashSidebar/DashSidebar';

import './DashboardLayout.css';

function DashboardLayout({ children, activePage }) {

    const [ shift, setShift ] = useState(false);

    const toggleMobileMenu = (e) => {
        setShift(!shift);
    }

    return (
        <div className="dashboard-layout">
            <DashHeader toggleMobileMenu={toggleMobileMenu} shift={shift} />
            <DashSidebar shift={shift} activePage={activePage} />
            <div className={`view-wrapper ${shift ? 'shift' : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default DashboardLayout;
