import React, { useState } from 'react';
import {theme} from '../../utils/theme';

import { faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Alert.css';

const Alert = ({ title, message, showAlert = true, setShow }) => {

    return (
        <section className={`alert-section ${showAlert ? 'show-alert' : ''}`}>
            <div className='alert-backdrop'></div>
            <div className='alert-content'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='alert-title mb-0'>
                        <FontAwesomeIcon 
                            className='icon'
                            icon={faInfoCircle}
                            color={theme.primary}
                        />&nbsp;
                        {title}
                    </h4>
                </div>
                <hr />
                <p className='alert-body'>
                    {message}
                </p>
                <div className='mt-3 d-flex justify-content-center align-items-center'>
                    <button className='btn btn-primary' onClick={() => setShow(false)}>
                        <FontAwesomeIcon 
                            className='icon'
                            icon={faTimesCircle}
                        /> &nbsp;
                        Close
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Alert;