import React, { useState, useEffect, useContext } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {apiUrl} from '../../utils';

import './Recover.css';

import logo from '../../utils/assets/logo.png';

function RecoverPassword() {

    const [cookie, setCookie] = useCookies(["session"]);

    const apiBaseUrl = apiUrl;

    const initialState = {
        email: "",
        isSubmitting: false,
        errorMessage: null,
        successMessage: null
    };

    const [data, setData] = useState(initialState);

    const handleChange = (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorMessage: null,
            successMessage: null,
            [e.target.name]: e.target.value
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
            
        if (data.email !== '') {
            setData({
                ...data,
                isSubmitting: true,
                errorMessage: null
            });
    
            axios.post(`${apiBaseUrl}/auth/forgot-password`, {
                email: data.email
            })
            .then(res => {

                setData({
                    ...data,
                    isSubmitting: false,
                    successMessage: 'Successful. Check your email to reset password.',
                    errorMessage: null,
                    email: ""
                });
            })
            .catch(error => {
                setData({
                    ...data,
                    isSubmitting: false,
                    successMessage: null,
                    errorMessage: error.statusText || 'Email verification failed. Try again!'
                });
                
            });
        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: "Empty field"
            });
        }

    };

    return (
        <main>
            <section className="recover-section">
                <div className="container">
                    <div className="recover">
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <a className="navbar-brand" href="/">
                                <img className="logo" src={logo} alt="logo" />
                            </a>
                        </div>
                        <h4 className="text-center mb-4">Recover Password</h4>
                        {
                            data.successMessage && (
                                <div className="alert alert-success">{data.successMessage}</div>
                            )
                        }
                        {
                            data.errorMessage && (
                                <div className="alert alert-danger">{data.errorMessage}</div>
                            )
                        }
                        <form className="w-100" onSubmit={handleSubmit} >
                            <div className="form-group mb-3">
                                <input type="email" name="email" className="form-control" placeholder="Enter Email" onChange={handleChange} value={data.email} noValidate />
                            </div>
                            <p className="text-center">
                                <button type="submit" className="btn btn-primary btn-block" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "SUBMIT"}</button>
                            </p>
                        </form>
                    </div>
                </div>
            </section>
            
        </main>
    )
}

export default RecoverPassword;
