import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import axios from 'axios';
import DashboardLayout from '../../../layout/DashboardLayout/DashboardLayout';
import {theme} from '../../../utils/theme';

import { faClock, faCoins, faExchangeAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {apiUrl} from '../../../utils';

import './Index.css';

function DashHome() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const initialState = {
        accounts: null,
        transactions: null,
        loans: null
    };

    const [data, setData] = useState(initialState);

    useEffect( () => {
        
        async function fetchData () {
            try {
                const user = cookie.session.user;
                const token = cookie.session.token;

                let accounts, transactions, loans;

                // Account
                const res = await axios.get(`${apiBaseUrl}/account`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });

                if (res) {
                    // console.log("Can't find account...")
                    accounts = res.data.accounts;
                }

                //  Transactions
                const res2 = await axios.get(`${apiBaseUrl}/transaction`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })

                if (res2) {
                    // console.log("Can't find transactions...")
                    transactions = res2.data.transactions;
                }

                //  Loans
                const res3 = await axios.get(`${apiBaseUrl}/loan`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })

                if (res3) {
                    // console.log("Can't find transactions...")
                    loans = res3.data.loans;
                }

                setData({
                    ...data,
                    accounts: accounts,
                    transactions: transactions,
                    loans: loans
                });
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();

    }, [apiBaseUrl]);

    return (
        <DashboardLayout activePage="0">
            <main>
                {/* Info */}
                <section className="info-section">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-md-4 mt-3">
                                <div className="w-100 h-100 info-content">
                                    <div className="info-top">
                                        <div className="info-icon-wrapper info-icon-wrapper1">
                                            <FontAwesomeIcon 
                                                icon={faCoins} 
                                                color={theme.light} 
                                                size="2x"
                                                className="icon" 
                                            />
                                        </div>
                                        <div className="text-right">
                                            <p className="mb-0">Loan Requests</p>
                                            <h4 className="">{data.loans ? data.loans.length : 0}</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="info-bottom d-flex align-items-center">
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faClock} 
                                                className="icon" 
                                                color={theme.gray}
                                            />
                                        </span> &nbsp;
                                        <span className="">
                                            Currently Updated
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="w-100 h-100 info-content">
                                    <div className="info-top">
                                        <div className="info-icon-wrapper info-icon-wrapper2">
                                            <FontAwesomeIcon 
                                                icon={faExchangeAlt} 
                                                color={theme.light} 
                                                size="2x"
                                                className="icon" 
                                            />
                                        </div>
                                        <div className="text-right">
                                            <p className="mb-0">Transactions</p>
                                            <h4 className="">{data.transactions ? data.transactions.length : 0}</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="info-bottom d-flex align-items-center">
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faClock} 
                                                className="icon" 
                                                color={theme.gray}
                                            />
                                        </span> &nbsp;
                                        <span className="">
                                            Currently Updated
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="w-100 h-100 info-content">
                                    <div className="info-top">
                                        <div className="info-icon-wrapper info-icon-wrapper3">
                                            <FontAwesomeIcon 
                                                icon={faUsers} 
                                                color={theme.light} 
                                                size="2x"
                                                className="icon" 
                                            />
                                        </div>
                                        <div className="text-right">
                                            <p className="mb-0">Accounts</p>
                                            <h4 className="">{data.accounts ? data.accounts.length : 0}</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="info-bottom d-flex align-items-center">
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faClock} 
                                                className="icon" 
                                                color={theme.gray}
                                            />
                                        </span> &nbsp;
                                        <span className="">
                                            Currently Updated
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Transaction */}
                <section className="loan-section">
                    <div className="container">
                        <h4 className="">Latest Transactions</h4>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">S/N</th>
                                        <th scope="col">DATE</th>
                                        <th scope="col">AMOUNT($)</th>
                                        <th scope="col">SENT BY</th>
                                        <th scope="col">RECEIVED BY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.transactions ? 
                                            data.transactions.slice(0, 15).map((transaction, i) => {
                                                return (
                                                    <tr key={i + 1}>
                                                        <td className="">
                                                            { i + 1 }
                                                        </td>
                                                        <td className="">{(new Date(transaction.createdAt)).toDateString()}</td>
                                                        <td className="text-right">{transaction.amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                        <td className="text-danger">
                                                            Metropolis Bank<br />
                                                            {transaction?.sender ? transaction.sender.accountNumber : ''}<br />
                                                            {transaction?.sender ? transaction.sender.accountName : ''}
                                                        </td>
                                                        <td className="text-success">
                                                            {
                                                                transaction?.bankname ?
                                                                    `${transaction?.bankname}`
                                                                    :
                                                                    `Metropolis Bank`
                                                            }<br />
                                                            {transaction?.beneficiaryNumber ? transaction.beneficiaryNumber : ''}
                                                            {transaction?.beneficiaryNumber && <br />}
                                                            {transaction?.beneficiaryNumber ? transaction.beneficiaryName : ''}
                                                            {transaction?.beneficiaryNumber && <br />}
                                                            {transaction?.beneficiary ? transaction.beneficiary.accountNumber : ''}
                                                            {transaction?.beneficiary && <br />}
                                                            {transaction?.beneficiary ? transaction.beneficiary.accountName : ''}
                                                            {transaction?.beneficiary && <br />}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default DashHome;
