import React, { useState, useEffect } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/AccountLayout/AccountLayout';
import './MyCard.css'
import {theme} from '../../../utils/theme';

import { faCalendar, faCaretRight, faCog, faInfoCircle, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { faGooglePay } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../../utils/assets/icon.png';

const MyCard = () => {

    const [cookie, setCookie] = useCookies(["session"]);

    const [user, setUser] = useState();

    useEffect(() => {
        const user = cookie.session.user;

        setUser(user);
        
    }, [cookie.session.user])

    return (
        <DashboardLayout activePage={3}>
            <main className='card-section'>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">My Card</div>
                    </div>
                </section>

                {/** Card */}
                <section className='card-content'>
                    <div className='container'>
                        <div className='mt-3 d-flex justify-content-start align-items-center'>
                            <FontAwesomeIcon 
                                className='icon'
                                icon={faInfoCircle}
                                color={theme.grayish}
                            />&nbsp;
                            <p className='mb-0'>SHOW DETAILS</p>
                        </div>
                        <div className='atm-card'>
                            <img className='logo' src={logo} alt='...' />
                            <h3 className=''>Metropolis</h3>
                            <h2 className=''>BANK</h2>
                            <h4 className=''>XXXX XXXX XXXX 5497</h4>
                            <h5 className=''>debit</h5>
                            <h6 className=''>{ user ? `${user.firstName} ${user.lastName}` : null }</h6>
                            <div className='circles-wrapper'>
                                <div className='circles'>
                                    <div className='circle circle1'></div>
                                    <div className='circle circle2'></div>
                                </div>
                            </div>
                        </div>
                        <div className='card-options mt-3'>
                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                <p className='mb-0'>Card status: Disabled</p>
                                <FontAwesomeIcon 
                                    className='icon'
                                    icon={faToggleOff}
                                    color={theme.primary}
                                />
                            </div>
                            <button className='btn btn-block border' type='button' disabled={true}>
                                Report as lost, stolen, or damaged
                            </button>
                            <div className='my-3'>
                                <span className=''>MOBILE WALLET</span>
                            </div>
                            <button className='btn-g-pay' type='button'>
                                Add to &nbsp; <FontAwesomeIcon icon={faGooglePay} color={theme.primary} size="2x" />
                            </button>
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default MyCard;