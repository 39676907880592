import React, { useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import {theme} from '../../utils/theme';

import { faTh, faPowerOff, faCoins, faFileArchive, faExchangeAlt, faCog, faHome, faPlusCircle, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './AcctSidebar.css';

import logo from '../../utils/assets/logo2.png';

function AcctSidebar({ shift, activePage }) {

    const [cookie, setCookie, removeCookie] = useCookies(["session"]);

    const pages = [
        { title: "Home", icon: faHome, link: "/account" },
        { title: "Add Money", icon: faPlusCircle, link: "/account/add-money" },
        { title: "Send Money", icon: faExchangeAlt, link: "/account/move-money" },
        { title: "My Card", icon: faIdCard, link: "/account/card" },
        { title: "More", icon: faCog, link: "/account/more" },
      ];

    const handleLogout = () => {
        removeCookie("session", {
            // httpOnly: true,
            // secure: process.env.NODE_ENV !== "development",
            path: "/",
            expires: new Date(0),
            sameSite: true,
        });

        removeCookie("type", {
            // httpOnly: true,
            // secure: process.env.NODE_ENV !== "development",
            path: "/",
            expires: new Date(0),
            sameSite: true,
        });
    }

    return (
        <div className={`acct-sidebar-nav ${shift ? 'show' : ''}`}>
            <aside className={``}>
                <div className="">
                    <div className="navbar-nav">
                        {/* <span className="nav-item text-center">
                            <a href='/dashboard'>Uni-Credit Bank</a>
                        </span> */}
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            {/* <a className="navbar-brand" href="/"> */}
                                <img className="logo" src={logo} alt="logo" />
                            {/* </a> */}
                        </div>
                        <hr />
                    </div>
                    <ul className="navbar-nav">
                        {
                            pages.map((page, i) => (
                                <li key={i + 1} className={`nav-item ${(i === activePage) ? 'active' : ''}`}>
                                    <a href={`${page.link}`}>
                                        <FontAwesomeIcon icon={page.icon} color={theme.light} className="icon" /> &nbsp; {page.title}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <ul className="navbar-nav">
                    <li className="nav-item" onClick={handleLogout}>
                        <a href='/'>
                            <FontAwesomeIcon icon={faPowerOff} color={theme.light} /> &nbsp; Sign Out
                        </a>
                    </li>
                </ul>
            </aside>
        </div>
    )
}

export default AcctSidebar;
