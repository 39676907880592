import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/AccountLayout/AccountLayout';
import './More.css'
import {theme} from '../../../utils/theme';

import { faCalendar, faCaretRight, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const More = () => {

    const [cookie, setCookie, removeCookie] = useCookies(["session"]);

    const history = useHistory();

    const goToATM = (e) => {
        history.push('/account/card');
    }

    const goToTransfer = (e) => {
        history.push('/account/move-money');
    }

    const goToSettings = (e) => {
        history.push('/account/settings');
    }

    const goToHistory = (e) => {
        history.push('/account/history');
    }

    const goToLoans = (e) => {
        history.push('/account/loan');
    }

    const handleLogout = () => {
        removeCookie("session", {
            // httpOnly: true,
            // secure: process.env.NODE_ENV !== "development",
            path: "/",
            expires: new Date(0),
            sameSite: true,
        });

        removeCookie("type", {
            // httpOnly: true,
            // secure: process.env.NODE_ENV !== "development",
            path: "/",
            expires: new Date(0),
            sameSite: true,
        });
    }
    
    return (
        <DashboardLayout activePage={4}>
            <main className='more-section'>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">More</div>
                    </div>
                </section>
                {/** Content */}
                <section className=''>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4 mt-1'>
                                <div className='more-content d-flex justify-content-between align-items-center' onClick={goToATM}>
                                    <h6 className='mb-0'>No-Fee ATM</h6>
                                    <FontAwesomeIcon 
                                        className='icon'
                                        icon={faCaretRight}
                                        color={theme.primary}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='more-content d-flex justify-content-between align-items-center' onClick={goToTransfer}>
                                    <h6 className='mb-0'>Send Money</h6>
                                    <FontAwesomeIcon 
                                        className='icon'
                                        icon={faCaretRight}
                                        color={theme.primary}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='more-content d-flex justify-content-between align-items-center' onClick={goToHistory}>
                                    <h6 className='mb-0'>Transaction History</h6>
                                    <FontAwesomeIcon 
                                        className='icon'
                                        icon={faCaretRight}
                                        color={theme.primary}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='more-content d-flex justify-content-between align-items-center' onClick={goToLoans}>
                                    <h6 className='mb-0'>Loans</h6>
                                    <FontAwesomeIcon 
                                        className='icon'
                                        icon={faCaretRight}
                                        color={theme.primary}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='more-content d-flex justify-content-between align-items-center' onClick={goToSettings}>
                                    <h6 className='mb-0'>My settings</h6>
                                    <FontAwesomeIcon 
                                        className='icon'
                                        icon={faCaretRight}
                                        color={theme.primary}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='more-content d-flex justify-content-between align-items-center' onClick={handleLogout}>
                                    <h6 className='mb-0'>Log out</h6>
                                    <FontAwesomeIcon 
                                        className='icon'
                                        icon={faCaretRight}
                                        color={theme.primary}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default More;