import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie';
import axios from 'axios';
import DashboardLayout from '../../../layout/AccountLayout/AccountLayout';
import {theme} from '../../../utils/theme';

import { faArrowRight, faCalendar, faClock, faComments, faEllipsisV, faGift, faCaretRight, faIdCard, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../../../component/Alert/Alert';

import {apiUrl} from '../../../utils';

import './Index.css';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';

function Home() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const history = useHistory();

    const initialState = {
        account: null,
        transactions: null,
    };

    const [data, setData] = useState(initialState);
    const [ show, setShow ] = useState(false);

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;

        // Account
        axios.get(`${apiBaseUrl}/account/user/${user._id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(res => {
            // console.log(res.data)
            setData({
                ...data,
                account: res.data.account
            });
        })
        .catch(err => console.log(err))
        
    }, [apiBaseUrl]);

    const handleAlertShow = (e) => {
        setShow(true);
    }

    return (
        <DashboardLayout activePage={0}>
            <main className='acct-dash'>
                {/** Alert Info */}
                <Alert 
                    className={`${show ? 'show-alert' : 'hide-alert'}`}
                    title="Info" 
                    message="Feature Unavailable. Coming soon..."
                    showAlert={show}
                    setShow={setShow}
                />
                {/* Info */}
                <section className="acct-dash-info-section">
                    <div className="container">
                        <div className="row welcome-section">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-column justify-content-center h-100 p-2">
                                        <h5 className="">Hello, {`${data.account ? data.account.user ? data.account.user.firstName.toUpperCase() : '' : ''} ${data.account ? data.account.user ? data.account.user.lastName.toUpperCase() : '' : ''}`}</h5>
                                        <p className="">{`${data.account ? data.account.user ? data.account.user.email : '' : ''}`}</p>
                                        <p className='mb-0'>Make payments easy with automatic bill pay.</p>
                                    </div>
                                    <div className='h-100'>
                                        <span className="welcome-arrow">
                                            <FontAwesomeIcon 
                                                icon={faArrowRight} 
                                                className="icon"
                                                color={theme.light}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3"><h6 className='mb-0 mx-3'>ACCOUNTS</h6></div>
                        <div className="row">
                            <div className="col-md-4 mt-1">
                                <div className="w-100 h-100 info-content">
                                    <div className="info-top">
                                        <div className='d-flex justify-content-between'>
                                            <div className="">
                                                <p className="mb-0">Checking Bal.</p>
                                                <h4 className="mb-0">${data.account ? data.account.balance.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0}</h4>
                                            </div>
                                            <div className='h-100'>
                                                <span className="">
                                                    <FontAwesomeIcon 
                                                        icon={faEllipsisV} 
                                                        className="icon" 
                                                        color={theme.primary}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="info-bottom d-flex align-items-center">
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faClock} 
                                                className="icon" 
                                                color={theme.gray}
                                            />
                                        </span> &nbsp;
                                        <span className="">
                                            Currently Updated
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-1">
                                <div className="w-100 h-100 info-content">
                                    <div className="info-top">
                                        <div className='d-flex justify-content-between'>
                                            <div className="">
                                                <p className="mb-0">Account No:</p>
                                                <h4 className="mb-0">{data.account ? data.account.accountNumber : ''}</h4>
                                            </div>
                                            <div className='h-100'>
                                                <span className="">
                                                    <FontAwesomeIcon 
                                                        icon={faEllipsisV} 
                                                        className="icon" 
                                                        color={theme.primary}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="info-bottom d-flex align-items-center">
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faClock} 
                                                className="icon" 
                                                color={theme.gray}
                                            />
                                        </span> &nbsp;
                                        <span className="">
                                            Currently Updated
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-1">
                                <div className="w-100 h-100 info-content">
                                    <div className="info-top">
                                        <div className='d-flex justify-content-between'>
                                            <div className="">
                                                <p className="mb-0">Account Type</p>
                                                <h4 className="mb-0">{data.account ? data.account.accountType : ''}</h4>
                                            </div>
                                            <div className='h-100'>
                                                <span className="">
                                                    <FontAwesomeIcon 
                                                        icon={faEllipsisV} 
                                                        className="icon" 
                                                        color={theme.primary}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="info-bottom d-flex align-items-center">
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faClock} 
                                                className="icon" 
                                                color={theme.gray}
                                            />
                                        </span> &nbsp;
                                        <span className="">
                                            Currently Updated
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/** Shortcuts */}
                <section className='shortcuts'>  
                    <div className='container'>
                        <div className="mt-3"><h6 className='mb-0 mx-3'>SHORTCUTS</h6></div>
                        <div className='row'>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={handleAlertShow}>
                                    <div className='shortcut-icon h-100 d-flex justify-content-center align-items-center'>
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faCalendar} 
                                                className="icon" 
                                                color={theme.primary}
                                            />
                                        </span>
                                    </div>
                                    <div className='shortcut-wrap d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                Get paid up to 2 days early
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Set up direct deposit and you can get your paycheck up to 2 days early.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={handleAlertShow}>
                                    <div className='shortcut-icon h-100 d-flex justify-content-center align-items-center'>
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faGift} 
                                                className="icon" 
                                                color={theme.primary}
                                            />
                                        </span>
                                    </div>
                                    <div className='shortcut-wrap d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                My perks
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Take a look at some sweet perks! There's alot to suit your needs.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={handleAlertShow}>
                                    <div className='shortcut-icon h-100 d-flex justify-content-center align-items-center p-2'>
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faTelegramPlane} 
                                                className="icon" 
                                                color={theme.primary}
                                            />
                                        </span>
                                    </div>
                                    <div className='shortcut-wrap d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                No-fee ATMs
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Access over 55,000 ATMs. Find a location near you.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={() => {history.push('/account/card')}}>
                                    <div className='shortcut-icon h-100 d-flex justify-content-center align-items-center p-2'>
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faIdCard} 
                                                className="icon" 
                                                color={theme.primary}
                                            />
                                        </span>
                                    </div>
                                    <div className='shortcut-wrap d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                My card
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Quickly access your cards. Readily availability is key.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={() => {history.push('/account/loan')}}>
                                    <div className='shortcut-icon h-100 d-flex justify-content-center align-items-center p-2'>
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faPlusCircle} 
                                                className="icon" 
                                                color={theme.primary}
                                            />
                                        </span>
                                    </div>
                                    <div className='shortcut-wrap d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                Get more MONEY
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Explore and get additional Metropolis Bank MONEY.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={handleAlertShow}>
                                    <div className='shortcut-icon h-100 d-flex justify-content-center align-items-center p-2'>
                                        <span className="">
                                            <FontAwesomeIcon 
                                                icon={faComments} 
                                                className="icon" 
                                                color={theme.primary}
                                            />
                                        </span>
                                    </div>
                                    <div className='shortcut-wrap d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                Need help?
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Get support 365 days/year, 8AM - 12AM ET.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                
            </main>
        </DashboardLayout>
    )
}

export default Home;
