export const theme =  {
      primaryOld: "#00A9A4",
      primary: "#00A9A4",
      secondaryOld: "#030734",
      secondary: "#030734",
      light: "#ffffff",
      muted: "#e0e0e0",
      highlight: "#9f9f9f",
      gray: "#707070",
      grey: "#5b5b5b",
      info: "#3672FF",
      accent: "#001D6E",
      black: "#000000",
      lightGray: '#f7f7f7',
      whitish: '#8273fc0d',
      variant: '#3f3A64',
      grayish: '#ccc',
      pink: "#FF3693",
      bgRed: "#ef5350",
      bgPink: "#f06292",
      bgLightBlue: "#40c4ff",
      bgDeepBlue: "#78909c",
      bgCyan: "#80cbc4",
      bgBlue: "#7aa2cb",
      blackish: '#666666',
};