import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import {theme} from '../../utils/theme';

import { faBars, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './DashHeader.css';

function DashHeader({ toggleMobileMenu, shift }) {

    const [cookie, setCookie] = useCookies(["session"]);

    const [user, setUser] = useState();

    useEffect(() => {
        const user = cookie.session.user;

        setUser(user);
        
    }, [cookie.session.user])

    return (
        <header className="dash-header">
            <nav className={`navbar navbar-expand-lg fixed-top ${shift ? 'shift' : ''}`}>
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={
                                (e) => { 
                                    e.preventDefault(); 
                                    e.stopPropagation(); 
                                    toggleMobileMenu(e);
                                }
                            }>
                        <FontAwesomeIcon icon={faBars} color={theme.secondaryOld} />
                    </button>
                    <div className="d-flex justify-content-center align-items-center px-3 ml-auto">
                        <span className="">
                            { user ? `${user.firstName} ${user.lastName}` : null }
                        </span>
                        <span className="">
                            <FontAwesomeIcon icon={faUserAlt} color={theme.secondary} className="ml-2" />
                        </span>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default DashHeader;
