import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function AdminAuth({ component }) {

    const Component = component;
    let isAuthenticated;

    const [cookie, setCookie] = useCookies(["session"]);

    if (cookie.session) {
        const user = cookie.session.user;
        const token = cookie.session.token;

        if(user && token) {
            if (user.type === "admin") {
                if(!token || token === "undefined" || token === "") {
                    isAuthenticated = false;
                } else {
                    isAuthenticated = true;
                }
            } else {
                isAuthenticated = false;
            }
        } else {
            isAuthenticated = false;
        }
    } else {
        isAuthenticated = false;
    }

    return (
        <div>
            {
                isAuthenticated ?
                    (
                        <Component />
                    )
                    : (
                        <Redirect to={{pathname: '/login'}} />
                    )
            }
        </div>
    )
}

export default AdminAuth;
