import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import { useCookies } from 'react-cookie';

function UserAuth({ component }) {

    const Component = component;
    let isAuthenticated;

    const [cookie, setCookie] = useCookies(["session"]);

    if (cookie.session) {
        const user = cookie.session.user;
        const token = cookie.session.token;

        if(user && token) {
            if (user.type === "user") {
                if(!token || token === "undefined" || token === "") {
                    isAuthenticated = false;
                } else {
                    isAuthenticated = true;
                }
            } else {
                isAuthenticated = false;
            }
        } else {
            isAuthenticated = false;
        }
    } else {
        isAuthenticated = false;
    }

    return (
        <Route 
            render = {(props) => {
                if (isAuthenticated) {

                    return <Component />

                } else {
                    
                    return <Redirect to={{
                            pathname: '/login',
                            state: {
                                referrer: props.location.pathname
                            }
                        }} 
                    />
                }
            }}
        />
    )
}

export default UserAuth;
