import React from 'react';
import {theme} from '../../utils/theme';

import { faExchangeAlt, faCog, faHome, faPlusCircle, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './MobileMenu.css';

const MobileMenu = ({ activePage }) => {

    const pages = [
        { title: "Home", icon: faHome, link: "/account" },
        { title: "Add Money", icon: faPlusCircle, link: "/account/add-money" },
        { title: "Send Money", icon: faExchangeAlt, link: "/account/move-money" },
        { title: "My Card", icon: faIdCard, link: "/account/card" },
        { title: "More", icon: faCog, link: "/account/more" },
    ];

    return (
        <section className='mobile-menu'>
            <div className='d-flex justify-content-around align-items-center w-100'>
                {
                    pages.map((page, i) => (
                        <div key={i + 1} className={`nav-item p-1 ${(i === activePage) ? 'active' : ''}`}>
                            <a className='d-flex flex-column justify-content-center align-items-center' href={`${page.link}`}>
                                <FontAwesomeIcon icon={page.icon} color={theme.light} className="icon" />
                                {page.title}
                            </a>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default MobileMenu;