import React, { useState, useEffect, useContext } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { apiUrl, appUrl } from '../../utils';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Register.css';

import logo from '../../utils/assets/logo.png';

function Register() {

    const [cookie, setCookie] = useCookies(["session"]);

    const apiBaseUrl = apiUrl;

    const initialState = {
        email: "",
        password: "",
        confirmPassword: "",
        accountType: "Savings",
        displayPassword: false,
        isSubmitting: false,
        errorMessage: null,
        successMessage: null
    };

    const [data, setData] = useState(initialState);

    const handleChange = (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorMessage: null,
            successMessage: null,
            [e.target.name]: e.target.value
        });

    };

    const toggleDisplayPassword = (e) => {
        e.preventDefault();

        setData({
            ...data,
            displayPassword: !data.displayPassword
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data.email !== '' && data.firstname !== '' && data.lastname !== '' && data.password !== '' && data.confirmPassword !== '' && data.accountType !== '') {
            
            if (data.password === data.confirmPassword) {
                setData({
                    ...data,
                    isSubmitting: true,
                    errorMessage: null
                });
        
                axios.post(`${apiBaseUrl}/auth/register`, {
                    firstName: data.firstname,
                    lastName: data.lastname,
                    email: data.email,
                    password: data.password,
                    accountType: data.accountType
                })
                .then(res => {
    
                    setData({
                        ...data,
                        isSubmitting: false,
                        successMessage: 'Registration successful. Your account will be verified and activated within 24 hours.',
                        errorMessage: null,
                        firstname: "",
                        lastname: "",
                        email: "",
                        password: "",
                        confirmPassword: "",
                    });
                })
                .catch(error => {
                    setData({
                        ...data,
                        isSubmitting: false,
                        successMessage: null,
                        errorMessage: (error?.response?.data?.message) || 'Registration unsuccessful. Try again!'
                    });
                    
                });
            } else {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "Passwords don't match"
                });
            }

        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty field(s)'
            });
        }

    };

    return (
        <main>
            <section className="register-section">
                <div className="container">
                    <div className="register">
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <a className="navbar-brand" href={appUrl}>
                                <img className="logo" src={logo} alt="logo" />
                            </a>
                        </div>
                        <h3 className="text-center mb-4">Sign up</h3>
                        {
                            data.successMessage && (
                                <div className="alert alert-success">{data.successMessage}</div>
                            )
                        }
                        {
                            data.errorMessage && (
                                <div className="alert alert-danger">{data.errorMessage}</div>
                            )
                        }
                        <form className="w-100" onSubmit={handleSubmit} >
                            <div className="form-group mb-3">
                                <input type="text" name="firstname" className="form-control" placeholder="First Name" onChange={handleChange} value={data.firstname} noValidate />
                            </div>
                            <div className="form-group mb-3">
                                <input type="text" name="lastname" className="form-control" placeholder="Last Name" onChange={handleChange} value={data.lastname} noValidate />
                            </div>
                            <div className="form-group mb-3">
                                <input type="email" name="email" className="form-control" placeholder="Email" onChange={handleChange} value={data.email} noValidate />
                            </div>
                            <div className="input-group mb-3">
                                <input type={ data.displayPassword ? 'text' : 'password' } name="password" className="form-control" placeholder="Password" value={data.password} onChange={handleChange} noValidate />
                                <div className="input-group-append">
                                    <span className="input-group-text" onClick={toggleDisplayPassword}>
                                        { data.displayPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
                                    </span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <input type="password" name="confirmPassword" className="form-control" placeholder="Confirm password" value={data.confirmPassword} onChange={handleChange} noValidate />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="accountType" className="form-label">Account Type</label>
                                <select name="accountType" onChange={handleChange} className="form-select w-100" aria-label="account type">
                                    <option value="Savings">Savings</option>
                                    <option value="Current">Current</option>
                                    <option value="Overdraft">Overdraft</option>
                                    <option value="Teen Account">Teen Account (for children)</option>
                                    <option value="FX Account">FX Account</option>
                                </select>
                            </div>
                            <p className="text-center">
                                <button type="submit" className="btn btn-primary btn-block" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "REGISTER"}</button>
                            </p>
                            <p className="text-center mt-4">
                                Already have an account? &nbsp;
                                <a href='/login'> 
                                    Sign in
                                </a>
                            </p>
                        </form>
                    </div>
                </div>
            </section>
            
        </main>
    )
}

export default Register;
