import React, { createContext, useReducer, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CookiesProvider, useCookies } from 'react-cookie';
import './App.css';
import ScrollToTop from './component/ScrollToTop/ScrollToTop';

import UserAuth from './component/UserAuth/UserAuth';
import AdminAuth from './component/AdminAuth/AdminAuth';

import Login from './pages/Login/Login';
import Recover from './pages/Recover/Recover';
import Register from './pages/Register/Register';
import Reset from './pages/Reset/Reset';

import AcctHome from './pages/Account/Index/Index';
import AcctHistory from './pages/Account/History/History';
import AcctLoan from './pages/Account/Loan/Loan';
import AcctTransfer from './pages/Account/Transfer/Transfer';
import AcctOtherTransfer from './pages/Account/OtherTransfer/OtherTransfer';
import AcctSetting from './pages/Account/Settings/Settings';
import AddMoney from './pages/Account/AddMoney/AddMoney';
import MoveMoney from './pages/Account/MoveMoney/MoveMoney';
import MyCard from './pages/Account/MyCard/MyCard';
import More from './pages/Account/More/More';

import DashHome from './pages/Dashboard/Index/Index';
import DashAccounts from './pages/Dashboard/Accounts/Accounts';
import DashFund from './pages/Dashboard/Fund/Fund';
import DashLoans from './pages/Dashboard/Loans/Loans';
import DashTransactions from './pages/Dashboard/Transactions/Transactions';

export const AuthContext = createContext();

const initialState = {
    isAuthenticated: false,
    user: null,
    token: null
};

const reducer = (state, action) => {
    switch(action.type) {
        case "LOGIN":
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token
            };
        case "LOGOUT":
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null
            };
        case "SET_COOKIE":
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token
            };
        default:
            return state;
    }
}

function App() {

    const [cookie, setCookie] = useCookies(["session"]);

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <CookiesProvider>
            <AuthContext.Provider 
                value={{
                    state,
                    dispatch
                }}
            >
                <Router>
                    <ScrollToTop />
                    <Switch>
                        <Route 
                            exact path="/" 
                            render={(props) => <Login {...props} />} 
                        />
                        <Route 
                            exact path="/login" 
                            render={(props) => <Login {...props} />} 
                        />
                        <Route 
                            exact path="/recover" 
                            render={(props) => <Recover {...props} />} 
                        />
                        <Route 
                            exact path="/register" 
                            render={(props) => <Register {...props} />} 
                        />
                        <Route 
                            path="/reset" 
                            render={(props) => <Reset {...props} />} 
                        />
                        <UserAuth 
                            exact path="/account" 
                            component={AcctHome} 
                        />
                        <UserAuth 
                            exact path="/account/history" 
                            component={AcctHistory} 
                        />
                        <UserAuth 
                            exact path="/account/loan" 
                            component={AcctLoan} 
                        />
                        <UserAuth 
                            exact path="/account/transfer" 
                            component={AcctTransfer} 
                        />
                        <UserAuth 
                            exact path="/account/transfer-other" 
                            component={AcctOtherTransfer} 
                        />
                        <UserAuth 
                            exact path="/account/settings" 
                            component={AcctSetting} 
                        />
                        <UserAuth 
                            exact path="/account/add-money" 
                            component={AddMoney} 
                        />
                        <UserAuth 
                            exact path="/account/move-money" 
                            component={MoveMoney} 
                        />
                        <UserAuth 
                            exact path="/account/card" 
                            component={MyCard} 
                        />
                        <UserAuth 
                            exact path="/account/more" 
                            component={More} 
                        />
                        <AdminAuth 
                            exact path="/dashboard" 
                            component={DashHome} 
                        />
                        <AdminAuth 
                            exact path="/dashboard/accounts" 
                            component={DashAccounts} 
                        />
                        <AdminAuth 
                            exact path="/dashboard/fund" 
                            component={DashFund} 
                        />
                        <AdminAuth 
                            exact path="/dashboard/loans" 
                            component={DashLoans} 
                        />
                        <AdminAuth 
                            exact path="/dashboard/transactions" 
                            component={DashTransactions} 
                        />
                        <Route 
                            render={(props) => <Login {...props} />} 
                        />
                    </Switch>
                </Router>
            </AuthContext.Provider>
        </CookiesProvider>
    );
}

export default App;
