import React, { useState, useContext } from 'react';
import { AuthContext } from '../../App';
import AcctHeader from '../../component/AcctHeader/AcctHeader';
import AcctSidebar from '../../component/AcctSidebar/AcctSidebar';
import MobileMenu from '../../component/MobileMenu/MobileMenu';

import './AccountLayout.css';

function DashboardLayout({ children, activePage }) {

    const { state } = useContext(AuthContext);

    const [ shift, setShift ] = useState(false);

    const toggleMobileMenu = (e) => {
        setShift(!shift);
    }

    return (
        <div className="account-layout">
            <AcctHeader toggleMobileMenu={toggleMobileMenu} shift={shift} />
            <AcctSidebar shift={shift} activePage={activePage} />
            <div className={`view-wrapper ${shift ? 'shift' : ''}`}>
                {children}
            </div>
            <MobileMenu activePage={activePage} />
        </div>
    )
}

export default DashboardLayout;
