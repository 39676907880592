import React, { useState, useEffect, useContext } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../App';
import { apiUrl, appUrl } from '../../utils';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Login.css';

import logo from '../../utils/assets/logo.png';

function Login() {

    const [cookie, setCookie] = useCookies(["session"]);

    const apiBaseUrl = apiUrl;

    const { state, dispatch } = useContext(AuthContext);

    const { isAuthenticated, user, token } = state;

    const history = useHistory();

    const initialState = {
        email: "",
        password: "",
        displayPassword: false,
        isSubmitting: false,
        errorMessage: null
    };

    const [data, setData] = useState(initialState);

    useEffect(() => {

        if (isAuthenticated && user) {
            if ( user.type === 'user') {
                history.push("/account");
            } else if (user.type === 'admin') {
                history.push("/dashboard");
            }
        }
        
    }, [state, history, isAuthenticated, user]);

    const handleChange = (e) => {
        e.preventDefault();

        setData({
            ...data,
            errorMessage: null,
            [e.target.name]: e.target.value
        });

    };

    const toggleDisplayPassword = (e) => {
        e.preventDefault();

        setData({
            ...data,
            displayPassword: !data.displayPassword
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data.email !== '' && data.password !== '') {
            setData({
                ...data,
                isSubmitting: true,
                errorMessage: null
            });
    
            axios.post(`${apiBaseUrl}/auth`, {
                email: data.email,
                password: data.password
            })
            .then(res => {
                // console.log(res.data)
                setCookie("type", JSON.stringify(res.data.user.type), {
                    // httpOnly: true,
                    // secure: process.env.NODE_ENV !== "development",
                    path: "/",
                    maxAge: 3600,
                    sameSite: true,
                });

                setCookie("session", JSON.stringify(res.data), {
                    // httpOnly: true,
                    // secure: process.env.NODE_ENV !== "development",
                    path: "/",
                    maxAge: 3600,
                    sameSite: true,
                });

                dispatch({
                    type: "LOGIN",
                    payload: {
                        user: res.data.user,
                        token: res.data.token
                    }
                });
                // setData({
                //     ...data,
                //     isSubmitting: false,
                // });
            })
            .catch(error => {
                setData({
                    ...data,
                    isSubmitting: false,
                    // errorMessage: error.response.data.message
                    errorMessage: (error?.response?.data?.message) || 'Incorrect Email or Password'
                });
                
            });
        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty fields'
            });
        }

    };

    return (
        <main>
            <section className="login-section">
                <div className="container">
                    <div className="login">
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <a className="navbar-brand" href={appUrl}>
                                <img className="logo" src={logo} alt="logo" />
                            </a>
                        </div>
                        <h3 className="text-center mb-4">Sign in</h3>
                        {
                            data.errorMessage && (
                                <div className="alert alert-danger">{data.errorMessage}</div>
                            )
                        }
                        <form className="w-100" onSubmit={handleSubmit} >
                            <div className="form-group mb-3">
                                <input type="email" name="email" className="form-control" placeholder="Email" onChange={handleChange} value={data.email} noValidate />
                            </div>
                            <div className="input-group mb-3">
                                <input type={ data.displayPassword ? 'text' : 'password' } name="password" className="form-control" placeholder="Password" onChange={handleChange} value={data.password} noValidate />
                                <div className="input-group-append">
                                    <span className="input-group-text" onClick={toggleDisplayPassword}>
                                        { data.displayPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
                                    </span>
                                </div>
                            </div>
                            <p className="text-center">
                                <button type="submit" className="btn btn-primary btn-block" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "LOGIN"}</button>
                            </p>
                            <p className="text-center mt-4 mb-0">
                                <a href='/recover'>
                                    Forgot your password?
                                </a>
                            </p>
                            <p className="text-center">
                                Don't have an account? &nbsp;
                                <a href='/register'> 
                                    Get Started
                                </a>
                            </p>
                        </form>
                    </div>
                </div>
            </section>
            
        </main>
    )
}

export default Login;
