import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/AccountLayout/AccountLayout';
import axios from 'axios';
import Pagination from '../../../component/Pagination/Pagination';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {apiUrl} from '../../../utils';

import './Loan.css';

function Loan() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const initialState = {
        account: null,
        loans: [],
        amount: '',
        reason: '',
        isSubmitting: false,
        errorMessage: null,
        successMessage: null
    };

    const [data, setData] = useState(initialState);

    const [ showModal, setShowModal ] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);

    //  Get Current Items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.loans.slice(indexOfFirstItem, indexOfLastItem);

    //  Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;

        // Account
        axios.get(`${apiBaseUrl}/account/user/${user._id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(res => {
            // console.log(res.data)
            setData({
                ...data,
                account: res.data.account
            });
        })
        .catch(err => console.log(err))
        
    }, [apiBaseUrl]);

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;
        //  Loan
        if (data.account) {
            axios.get(`${apiBaseUrl}/loan/account/${data.account._id}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                setData({
                    ...data,
                    loans: res.data.loans,
                    successMessage: null,
                    errorMessage: null,
                });
            })
            .catch(err => console.log(err))
        }
    }, [apiBaseUrl, data.account, data.isSubmitting]);

    const handleChange = (e) => {
        e.preventDefault();

        setData({
            ...data,
            [e.target.name]: e.target.value
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = cookie.session.user;
        const token = cookie.session.token;

        if (data.amount !== '' && data.reason !== '') {
            setData({
                ...data,
                isSubmitting: true,
                errorMessage: null,
                successMessage: null
            });
    
            axios.post(`${apiBaseUrl}/loan`, {
                accountId: data.account._id,
                amount: data.amount,
                reason: data.reason
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                // console.log(res.data)
                setData({
                    ...data,
                    isSubmitting: false,
                    successMessage: 'Submitted successfully',
                    errorMessage: null,
                    amount: '',
                    reason: '',
                });
                closeModal();
            })
            .catch(error => {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: error.message || error.statusText,
                    successMessage: null
                });
                
            });
        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: 'Empty fields',
                successMessage: null
            });
        }

    };

    const openModal = () => {

        setShowModal(true);

    }

    const closeModal = () => {

        setShowModal(false);
        
    }

    return (
        <DashboardLayout activePage={4}>
            <main>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">Loan Requests</div>
                    </div>
                </section>

                {/* Loan */}
                <section className="loan-section">
                    <div className="container">
                        <p className="text-right">
                            <button type="button" className="btn btn-primary" onClick={() => openModal()}>REQUEST FOR LOAN</button>
                        </p>
                        {
                            data.successMessage && (
                                <div className="alert alert-success">{data.successMessage}</div>
                            )
                        }
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {/* <th scope="col">S/N</th> */}
                                        <th scope="col">Date</th>
                                        {/* <th scope="col">Loan Package</th> */}
                                        <th scope="col">Amount($)</th>
                                        <th scope="col">Reason</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems ? 
                                            currentItems.map((loan, i) => {
                                                return (
                                                    <tr key={i + 1}>
                                                        {/* <td className="">{i + 1}</td> */}
                                                        <td className="">{(new Date(loan.createdAt)).toDateString()}</td>
                                                        {/* <td className="">
                                                            {
                                                                loan.loanPackage ? 
                                                                    loan.loanPackage.loanpackage
                                                                    : (
                                                                        <span className="text-info">-</span>
                                                                    )
                                                            }
                                                        </td> */}
                                                        <td className="text-right">{loan.amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                        <td className="">{loan.reason}</td>
                                                        <td className="">
                                                            {
                                                                loan.status ? (
                                                                    <span className="text-success">Approved</span>
                                                                )
                                                                : (
                                                                    <span className="text-info">Under review...</span>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                            <Pagination 
                                numberPerPage={itemsPerPage} 
                                totalNumber={data.loans.length} 
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </section>

                {/* LOAN REQUEST Modal */}
                <div className={`modal-overlay ${showModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showModal ? 'show' : ''}`} id="loanModal" tabIndex="-1" aria-labelledby="loanModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="addModalLabel">LOAN REQUEST</h5>
                                    <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="">
                                        {
                                            data.errorMessage && (
                                                <div className="alert alert-danger">{data.errorMessage}</div>
                                            )
                                        }
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label className="">Amount($)</label>
                                                <input type="number" name="amount" className="form-control" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label className="">Reason for loan request</label>
                                                <input type="text" name="reason" className="form-control" onChange={handleChange} />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-sm btn-secondary" onClick={() => closeModal()} disabled={data.isSubmitting}>CANCEL</button>
                                                <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "MAKE REQUEST"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </main>
        </DashboardLayout>
    )
}

export default Loan;
