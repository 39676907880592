import React, { useState, useEffect, useContext } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import DashboardLayout from '../../../layout/DashboardLayout/DashboardLayout';
import axios from 'axios';
import Pagination from '../../../component/Pagination/Pagination';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {apiUrl} from '../../../utils';

import './Loans.css';

function Loan() {

    const apiBaseUrl = apiUrl;

    const [cookie, setCookie] = useCookies(["session"]);

    const initialState = {
        loans: [],
        loan: null,
        isSubmitting: false,
        errorMessage: null,
        successMessage: null,
        refresh: false,
        searchItems: null
    };

    const [data, setData] = useState(initialState);

    const [ showModal, setShowModal ] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);

    //  Get Current Items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = data.loans.slice(indexOfFirstItem, indexOfLastItem);

    const [currentItems, setCurrentItems] = useState();

    //  Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        if (data.searchItems) {
            const currentItems = data.searchItems.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        } else {
            const currentItems = data.loans.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(currentItems);
        }
    }, [data.loans, currentPage, data.searchItems])

    useEffect(() => {
        const user = cookie.session.user;
        const token = cookie.session.token;
        //  Loan
        axios.get(`${apiBaseUrl}/loan`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(res => {
            setData({
                ...data,
                loans: res.data.loans,
                successMessage: null,
                errorMessage: null,
            });
        })
        .catch(err => console.log(err))
    }, [apiBaseUrl, data.refresh]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (e.target.value !== '') {
            const searchString = e.target.value.toLowerCase();
            const filteredItems = data.loans.filter(item => item.account.accountName.toLowerCase().includes(searchString) || item.account.accountNumber.includes(searchString));

            setCurrentPage(1);

            setData({
                ...data,
                searchItems: filteredItems
            })
        } else {
            setData({
                ...data,
                searchItems: null
            })
        }
    }

    const handleView = (id) => {

        const loan = data.loans.filter(loan => loan._id == id);
        openModal();
        // console.log(loan)
        setData({
            ...data,
            loan: loan
        });
        // console.log(loan)

    }

    const approveLoan = async (id) => {
        // console.log('Inside approve loan ' + id)
        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true
        });

        try {
            const res = await axios.patch(`${apiBaseUrl}/loan/${id}/approve`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
    
            // console.log('After waiting')
    
            if (res) {
                closeModal();
                setData({
                    ...data,
                    refresh: !data.refresh,
                    isSubmitting: false
                });
            }
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || "Not successful. Try again later!"
            });
        }

    }

    const unApproveLoan = async (id) => {

        const user = cookie.session.user;
        const token = cookie.session.token;

        setData({
            ...data,
            isSubmitting: true
        });

        try {
            const res = await axios.patch(`${apiBaseUrl}/loan/${id}/unapprove`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
    
            if (res) {
                closeModal();
                setData({
                    ...data,
                    refresh: !data.refresh,
                    isSubmitting: false
                });
            }
        } catch (error) {
            setData({
                ...data,
                isSubmitting: false,
                successMessage: null,
                errorMessage: error.statusText || "Not Successful. Try again later!"
            });
        }
        
    }

    const openModal = () => {

        setShowModal(true);

    }

    const closeModal = () => {

        setShowModal(false);
        
    }

    return (
        <DashboardLayout activePage={3}>
            <main>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">Loans</div>
                    </div>
                </section>

                {/* Loan */}
                <section className="loan-section">
                    <div className="container">
                        <div className="form-group mb-3">
                            <input type="text" name="search" className="form-control" placeholder="Search" onChange={handleSearch} />
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Date</th>
                                        {/* <th scope="col">LOAN PACKAGE</th> */}
                                        <th scope="col">Amount($)</th>
                                        <th scope="col">Reason</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems ? 
                                            currentItems.map((loan, i) => {
                                                return (
                                                    <tr key={i + 1}>
                                                        <td className="">
                                                            <button type="button" className="btn btn-primary" onClick={() => handleView(loan._id)}>View</button>
                                                        </td>
                                                        <td className="">{(new Date(loan.createdAt)).toDateString()}</td>
                                                        {/* <td className="">
                                                            {
                                                                loan.loanPackage ? 
                                                                    loan.loanPackage.loanpackage
                                                                    : (
                                                                        <span className="text-info">-</span>
                                                                    )
                                                            }
                                                        </td> */}
                                                        <td className="text-right">{loan.amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                        <td className="">{loan.reason}</td>
                                                        <td className="">
                                                            {
                                                                loan.status ? (
                                                                    <span className="text-success">Approved</span>
                                                                )
                                                                : (
                                                                    <span className="text-info">Under review...</span>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                            <Pagination 
                                numberPerPage={itemsPerPage} 
                                totalNumber={ data.searchItems ? data.searchItems.length : data.loans.length } 
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </section>

                {/* LOAN Modal */}
                <div className={`modal-overlay ${showModal ? 'show' : ''}`}></div>
                <div className={`modal fade ${showModal ? 'show' : ''}`} id="loanModal" tabIndex="-1" aria-labelledby="loanModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addModalLabel">LOAN</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()} disabled={data.isSubmitting}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="">
                                    <div className="modal-footer">
                                        {
                                            data.loan ? 
                                                data.loan[0].status ? 
                                                    (
                                                        <button type="button" className="btn btn-sm btn-secondary" onClick={() => unApproveLoan(data.loan[0]._id)} disabled={data.isSubmitting}>{data.isSubmitting ? "Loading..." : "UNAPPROVE"}</button>
                                                    )
                                                : (
                                                    <button type="submit" className="btn btn-sm btn-primary" disabled={data.isSubmitting} onClick={() => approveLoan(data.loan[0]._id)}>{data.isSubmitting ? "Loading..." : "APPROVE"}</button>
                                                )
                                            : null
                                        }
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                {/* <tr className="">
                                                    <th className="">LOAN PACKAGE</th>
                                                    <td className="">{data.loan ? data.loan[0].amount : ''}</td>
                                                </tr> */}
                                                <tr className="">
                                                    <th className="">AMOUNT</th>
                                                    <td className="">${data.loan ? data.loan[0].amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">REASON</th>
                                                    <td className="">{data.loan ? data.loan[0].reason : ''}</td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">ACCOUNT NAME</th>
                                                    <td className="">
                                                        {
                                                            data.loan ? data.loan[0].account.accountName : ''
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <th className="">ACCOUNT NUMBER</th>
                                                    <td className="">
                                                        {
                                                            data.loan ? data.loan[0].account.accountNumber : ''
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default Loan;
