import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DashboardLayout from '../../../layout/AccountLayout/AccountLayout';
import './AddMoney.css'
import {theme} from '../../../utils/theme';

import { faCaretRight, faCog, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '../../../component/Alert/Alert';

const AddMoney = () => {
    
    const [ show, setShow ] = useState(false);

    const history = useHistory();

    const handleAlertShow = (e) => {
        setShow(true);
    }

    return (
        <DashboardLayout activePage={1}>
            <main className='addmoney-section'>
                {/* Title */}
                <section className="title-wrapper">
                    <div className="container">
                        <div className="title text-center">Add Money</div>
                    </div>
                </section>
                {/** Alert Info */}
                <Alert 
                    className={`${show ? 'show-alert' : 'hide-alert'}`}
                    title="Info" 
                    message="Feature Unavailable. Coming soon..."
                    showAlert={show}
                    setShow={setShow}
                />

                {/** Shortcuts */}
                <section className='add-money-shortcuts'>  
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={handleAlertShow}>
                                    <div className='shortcut-wrap w-100 d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                Direct deposit
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Deposit your paycheck automatically and you can get paid up to 2 days.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={handleAlertShow}>
                                    <div className='shortcut-wrap w-100 d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                External transfer in
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Request money from another bank. Daily transfer limit: $100,000.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={handleAlertShow}>
                                    <div className='shortcut-wrap w-100 d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                Deposit check
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Snap a pic of the front and back of your check.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={handleAlertShow}>
                                    <div className='shortcut-wrap w-100 d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                Deposit cash
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Add cash at a participating merchant.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-1'>
                                <div className='h-100 d-flex info-content' onClick={handleAlertShow}>
                                    <div className='shortcut-wrap w-100 d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <h3 className='shortcut-title'>
                                                Send money from another bank.
                                            </h3>
                                            <p className='shortcut-content mb-0'>
                                                Your other bank's transfer limits apply.
                                            </p>
                                        </div>
                                        <div className='h-100 d-flex justify-content-center align-items-center p-2'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCaretRight} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mt-3 manage-section'>
                                <div className='h-100 w-100 d-flex justify-content-between align-items-center'>
                                    <div className='h-100 d-flex flex-column justify-content-center align-items-center info-content' onClick={() => history.push('/account/history')}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCalendar} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                        <p className='mb-0 mt-1 text-center'>
                                            BILL, PAYMENTS, HISTORY, TRANSFER STATUS
                                        </p>
                                    </div>
                                    <div className='h-100 d-flex flex-column justify-content-center align-items-center info-content' onClick={() => history.push('/account/settings')}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <span className="">
                                                <FontAwesomeIcon 
                                                    icon={faCog} 
                                                    className="icon" 
                                                    color={theme.primary}
                                                />
                                            </span>
                                        </div>
                                        <p className='mb-0 mt-1 text-center'>
                                            MANAGE EXTERNAL ACCOUNTS, BILL PAYEES
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </DashboardLayout>
    )
}

export default AddMoney;